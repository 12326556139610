
// components

export default {
    name: 'HomeMobileSlider',

    props: {
        slides: {
            type: Array,
            default: () => [],
        },

        /**
         * Любые другие опции для слайдера,
         * будут применены с высшим приоритетом
         */
        customOptions: {
            type: Object,
            default: () => ({}),
        },

        /**
         * Скорость прокрутки слайдера
         */
        speed: {
            type: Number,
            default: 1000,
        },

        /**
         * Кол-во отображаемых за раз слайдов
         */
        slidesPerView: {
            type: [String, Number],
            default: 'auto',
        },

        spaceBetween: {
            type: Number,
            default: 60,
        },

        centeredSlides: {
            type: Boolean,
            default: false,
        },

        /**
         * Индекс начального слайда
         */
        initialIndex: {
            type: Number,
            default: 0,
        },
        
        /**
         * Включить бесконечную прокрутку
         */
        autoplayDelay: {
            type: Number,
            default: 0,
        },

        loop: {
            type: Boolean,
            default: false,
        },

        freeMode: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            slider: null,
            activeIndex: 0,
        };
    },

    created() {
        this.activeIndex = this.initialIndex;
    },

    mounted() {
        this.$nextTick(() => {
            if (this.slides.length > 1) {
                this.initSlider();
            }
        });
    },

    beforeDestroy() {
        this.slider?.destroy();
        this.slider = null;
    },

    methods: {
        initSlider() {
            const options = {
                slidesPerView: this.slidesPerView,
                loop: this.loop,
                spaceBetween: this.spaceBetween,
                centeredSlides: this.centeredSlides,
                autoplay: !this.autoplayDelay ? false : { delay: this.autoplayDelay },
                speed: this.speed,
                allowTouchMove: true,
                observer: true,

                freeMode: {
                    enabled: this.freeMode,
                },

                modules: [
                    this.$SwiperModules.FreeMode,
                    this.$SwiperModules.Autoplay,
                    this.$SwiperModules.Navigation,
                    this.$SwiperModules.Scrollbar,
                ],

                navigation: {
                    nextEl: this.$refs.next || false,
                    prevEl: this.$refs.prev || false,
                },

                scrollbar: {
                    el: this.$refs.scrollbar,
                    draggable: true,
                    hide: false,
                    snapOnRelease: true,
                },

                on: {
                    slideChange: swiper => {
                        this.activeIndex = swiper.realIndex;
                    },
                },

                ...this.customOptions,
            };

            if (this.$refs.slider) {
                this.slider = new this.$Swiper(this.$refs.slider, options);
                this.slider.slideTo(this.activeIndex);
            }
        },
    },
};
